import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import QualtricsSurvey from '../../components/Qualtrics/QualtricsSurvey'

interface MatchParams {
  id: string
}

const SurveyPage: React.FC<RouteComponentProps<MatchParams>> = ({
  location,
  match,
}) => {
  return (
    <QualtricsSurvey
      surveyId={match.params.id}
      embeddedData={location.search}
    />
  )
}

export default withRouter(SurveyPage)
