import React from 'react'
import styles from './Footer.module.css'

const Footer: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        className="ln-c-link ln-c-link--bare ln-c-link--alpha ot-sdk-show-settings"
      >
        Manage Cookies
      </button>
    </div>
  )
}
export default Footer
