import React from 'react'
import { GlobalStyle } from './styles'
import Router from './components/Router'
import Head from './components/Head'
import { Footer } from './components/Footer'
import { useSurveyTheme } from './hooks/useSurveyTheme'
import './styles/app.sass'

const App: React.FC = () => {
  const theme = useSurveyTheme()
  return (
    <div className={`ds-theme--${theme.name} ln-u-fill-height`}>
      <Head />
      <GlobalStyle />
      <Router />
      <Footer />
    </div>
  )
}

export default App
