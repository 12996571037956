import { useReducer, useEffect, useCallback } from 'react'

export type ReducerFunc = (
  state: Record<string, unknown>,
  action: Record<string, unknown>
) => Record<string, unknown>

export const useMessagePortal = (
  allowedDomains: string[],
  reducer: ReducerFunc
): Record<string, unknown> => {
  const [state, dispatch] = useReducer(reducer, {})

  const handleMessage = useCallback(
    (event: MessageEvent): void => {
      if (
        allowedDomains.includes(event.origin) &&
        typeof event.data === 'string'
      ) {
        const { key, value } = JSON.parse(event.data)
        dispatch({ type: key, value })
      }
    },
    [allowedDomains]
  )

  useEffect(() => {
    window.addEventListener('message', handleMessage, false)

    return (): void => {
      window.removeEventListener('message', handleMessage, false)
    }
  })

  return state
}
