enum BRANDS {
  SAINSBURYS = 'sainsburys',
  ARGOS = 'argos',
  TU = 'tu',
  HABITAT = 'habitat',
  NECTAR = 'nectar',
}

const isValidBrand = (brand: unknown): brand is BRANDS => {
  return (
    brand !== undefined &&
    typeof brand === 'string' &&
    Object.values(BRANDS).includes(brand as BRANDS)
  )
}

export { isValidBrand, BRANDS }
