import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import SurveyPage from '../../pages/SurveyPage'
import IndexPage from '../../pages/IndexPage'

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/SV_:id" component={IndexPage} />
        <Route path="/survey/:id" component={SurveyPage} />
      </Switch>
    </BrowserRouter>
  )
}

export default Router
