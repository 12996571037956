enum Environments {
  local = 'local',
  dev = 'dev',
  prod = 'prod',
}

const isValidEnvironment = (
  environment: unknown
): environment is Environments => {
  return (
    environment !== undefined &&
    typeof environment === 'string' &&
    Object.values(Environments).includes(environment as Environments)
  )
}

const getCurrentEnvironment = (): Environments => {
  const envStage = process.env.REACT_APP_STAGE || process.env.STAGE
  if (isValidEnvironment(envStage)) {
    return envStage
  }
  return Environments.local
}

export { Environments, isValidEnvironment, getCurrentEnvironment }
