import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    position: relative;
    min-height: 100%;
    margin: 0;
  }

  .app {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export { GlobalStyle }
