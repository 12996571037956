import queryString from 'query-string'
import {
  themes,
  getBrandByThemeHost,
  SurveyTheme,
} from '@csat/common/constants/themes'
import { isValidBrand, BRANDS } from '@csat/common/constants/brands'

export const useSurveyTheme = (): SurveyTheme => {
  const parsedQueryString = queryString.parse(window.location.search)

  let brand: BRANDS
  if (isValidBrand(parsedQueryString?.theme)) {
    brand = parsedQueryString.theme
  } else {
    brand = getBrandByThemeHost(
      `${window.location.protocol}//${window.location.host}`
    )
  }

  return themes[brand]
}
