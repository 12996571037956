import React from 'react'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'

interface MatchParams {
  id: string
}

const IndexPage: React.FC<RouteComponentProps<MatchParams>> = ({
  location,
  match,
}) => {
  let surveyId = ''
  if (match.params.id) {
    surveyId += `SV_${match.params.id}`
  }
  if (location.search) {
    surveyId += `${location.search}`
  }

  return <Redirect to={`/survey/${surveyId}`} />
}

export default withRouter(IndexPage)
